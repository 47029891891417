.toteboard > ul {
	width: 100%;
	display: flex;
	list-style-type: none;
	padding: 0;
	flex-wrap: wrap;
}

.toteboard > ul > li {
	flex: 1;
	min-width: 13rem;
	height: 100%;
}

.toteboard > ul > li > div {
	width: 92%;
	height: 100%;
	padding: 0.8rem;
}

.toteboard > ul > li span:first-child {
	display: block;
	margin-bottom: 1rem;
	margin-left: 0rem;
}

.toteboardClickable {
	cursor: pointer;
}
