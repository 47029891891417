.iconBadgeContainer {
	display: flex;
	padding: 0 6px 0 2px;
	background: #ffd79d;
	border-radius: 10px;
}

.iconBadgeContainer svg {
	padding: 3px 0;
}

.progressContainer {
	display: flex;
}

.unitsContainer {
	padding: 2rem;
	flex: 1;
}

.issuesContainer {
	padding: 2rem;
	flex: 3;
	background: #fafbfb;
	border: 2px solid transparent;
	border-radius: 0px 8px 8px 0px;
}
