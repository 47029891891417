.detailsLabels {
	min-width: 120px;
}

.detailsLabels > div {
	padding: 4px 0;
}

.detailsData > div {
	padding: 4px 0;
}
