.detailsLabels {
	min-width: 120px;
}

.productColumns {
	display: grid;
	grid-template-columns: 1.4fr 1fr 1fr;
	grid-gap: 2rem;
	align-items: center;
}

.processedColumn {
	max-width: 160px;
}

.wrapText {
	max-width: 300px;
}
