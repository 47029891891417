.container {
	width: 100%;
	position: absolute;
	bottom: 0;
	top: 110px;
	left: 0px;
	right: 0px;
}

.container[data-filtered='true'] {
	top: 140px;
}

.container[data-fullscreen='false'] {
	border-top: 1px solid #ddd;
}

.container[data-fullscreen='false'] [data-type='workflowPoint'] {
	cursor: pointer;
}

.container[data-fullscreen='false'] [data-type='chuck'] {
	cursor: pointer;
}

[id='fsButton'] {
	margin: -0.3rem -0.8rem !important;
}

[id='listButton'] {
	position: absolute;
	left: 20px;
	top: 20px;
	z-index: 1;
}

[data-type='impassable'] {
	fill: #ddd !important;
	fill-opacity: 1 !important;
}

.list {
	position: absolute;
	width: 300px;
	z-index: 1;
	height: 100%;
	overflow: hidden;
	background: white;
	border-right: 1px solid #ddd;
	box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.15);
}

.listHead {
	position: fixed;
	width: 300px;
	height: 50px;
	background-color: white;
	border-right: 1px solid #ddd;
}

.listBody {
	margin-top: 50px;
	width: 300px;
	height: calc(100% - 50px);
	overflow: scroll;
}

.listHead,
.listRow {
	padding: 0.75em 1em;
	border-bottom: 1px solid #ddd;
}

.listRow {
	cursor: pointer;
	user-select: none;
}

.listRow[aria-selected='true'] {
	background: #f2f7fe;
}

.listRow:hover {
	background: #edf4fe;
}

.listRow:active {
	background: #e5effd;
}

.listRow a {
	color: var(--p-interactive);
}

.listRow a:hover {
	text-decoration: none;
}

.close {
	border: none;
	cursor: pointer;
	padding: 0.5rem;
	background: none;
}

.close:active {
	transform: scale(0.9);
}

.fault {
	background: #fff4f4;
	padding: 0.75rem 1rem;
	margin: -0.5rem;
	margin-bottom: 0.25rem;
	border: 1px solid rgb(224, 179, 178);
	color: var(--p-text-critical);
}

.form {
	position: absolute;
	width: 325px;
	left: 15px;
	top: 15px;
	z-index: 1;
}

.fsHeaderPanel {
	background: #0a111e;
	color: white;
	height: 12.5%;
	padding: 12px 20px;
	display: flex;
	justify-content: space-between;
	user-select: none;
}

.fsTablePanel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #182941;
	color: white;
	width: 33%;
	position: fixed;
	right: 0;
	top: 12.5%;
	bottom: 0;
	user-select: none;
}

.fsMapPanel {
	background: #ddd;
	width: 67%;
	position: fixed;
	bottom: 0;
	top: 12.5%;
}

.fsInfo {
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.fsTime {
	font-size: 2.5vh;
	font-weight: 600;
}

.fsTitle {
	font-size: 2vh;
}

.fsHeader {
	display: flex;
}

.fsStat {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 2px 12px 2px 12px;
	margin: 0 5px;
	border-radius: 3px;
	white-space: nowrap;
	min-width: 6vw;
}

.fsStat img {
	height: 1.75vh;
	margin-right: 0.25em;
}

.fsStatLabel {
	font-size: 2.2vh;
	line-height: 3vh;
}

.fsStatValue {
	font-size: 6.5vh;
	line-height: 6.5vh;
	font-weight: 600;
}

.fsTable table {
	width: 100%;
	border-collapse: collapse;
	white-space: nowrap;
}

.fsTable table th {
	padding: 2.5% 0.75em;
	text-align: left;
	font-size: 2vh;
	font-weight: normal;
	color: #929aaa;
	border-bottom: 1px solid #929aaa;
}

.fsTable table td {
	padding: calc((87.5vh / 15) / 4) 0.6em;
	font-weight: normal;
	font-size: min(2.5vh, calc((87.5vh / 15) / 2));
	line-height: min(2vh, calc((87.5vh / 15) / 2));
	overflow: hidden;
	text-overflow: ellipsis;
}

.fsOptions {
	padding: 1em 1.5em;
	text-align: right;
	display: flex;
	justify-content: space-between;
}

.fsOptions > select {
	background: none;
	border: none;
	outline: none;
	color: white;
	cursor: pointer;
}

.fsOptions > button {
	background: #273f63;
	border: none;
	color: white;
	cursor: pointer;
}

.fsTable td[data-low-battery='true'] {
	color: #ffe924;
}

.fsTable td[data-dwelling='true'] {
	color: #00e0ff;
}

.fsTable td[data-low-battery='false'],
.fsTable td[data-dwelling='false'] {
	color: rgba(255, 255, 255, 0.4);
}

.fsTable img {
	height: 2vh;
	margin-right: 0.25em;
}

.badge {
	margin-bottom: 12px;
}
